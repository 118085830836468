import React from "react";
import BranchOfficeInformation from "./BranchOfficeInformation";
import Carousel from "../../../share/components/Carousel/Carousel";
import MapContainer from "../../../share/components/Map/Map";

const imagesUrl = [
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-01.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-02.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-03.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-04.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-05.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-06.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-07.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-08.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-09.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-10.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-11.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-12.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-13.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-14.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados/sala-acabados-15.jpg",
];

const SalaDeAcabados = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <div>
      <div className="branch-information-title-container">
        <img
          alt="values"
          src="https://msc-images-prod.s3.amazonaws.com/images/sucursales/sala-acabados.png"
          className="branch-office-image-title-02"
        />
      </div>
      <Carousel imagesUrl={imagesUrl} />
      <BranchOfficeInformation
        schedule="De Lunes a Viernes de 8:00a.m. a 12:00m.d. y de 1:00p.m. a 6:00p.m. y los Sábados de 8:00a.m. a 12:00m.d. y de 1:00p.m. a 5:00p.m."
        address="200 mts Sur del BCR en Palmares Centro."
        telephone="2453-1553"
        whatsapp1="6296-3093"
      />
      <MapContainer
        locationUrl="https://www.google.com/maps/place/Cer%C3%A1micas+MSC/@10.0537997,-84.4316019,21z/data=!4m15!1m9!4m8!1m3!2m2!1d-84.4316277!2d10.0537674!1m3!2m2!1d-84.4316116!2d10.0537693!3m4!1s0x8fa05bb88e99477b:0x291f92d13174a026!8m2!3d10.0537591!4d-84.4316437?hl=es"
        location={{
          lat: 10.053753,
          lng: -84.431644,
        }}
      />
    </div>
  );
};

export default SalaDeAcabados;
