import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import MobileStepper from "@material-ui/core/MobileStepper";
import { useLocation } from "react-router";
import { productsInfo } from "../Products/Products";

const Product = () => {
  const location = useLocation<{ id: number }>();
  const [product, setRaffleProduct] = React.useState<any>(undefined);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (location && location.state && location.state.id) {
      const index = productsInfo.findIndex(
        (product) => product.id === location.state.id
      );
      const product = productsInfo[index];
      setRaffleProduct(product);
    }
  }, [location]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "#0691d1",
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ color: "#FFFFFF", fontSize: 30 }}>Publicidad</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 60,
          marginBottom: 60,
          flexWrap: "wrap",
        }}
      >
        <div style={{ flex: 2, marginLeft: "5%", marginRight: "5%" }}>
          <TextMobileStepper />
        </div>
        <div
          style={{
            minWidth: 300,
            flex: 1,
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <h1 style={{ fontWeight: "bold", color: "rgb(73, 73, 73)" }}>
            {product && product.name}
          </h1>
          <p
            style={{
              fontSize: 18,
              fontWeight: "lighter",
              marginRight: "5%",
              color: "rgb(73, 73, 73)",
            }}
          >
            Descripción
          </p>
          <p
            style={{
              fontSize: 18,
              fontWeight: "lighter",
              marginRight: "5%",
              color: "rgb(73, 73, 73)",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <h2 style={{ color: "#0691d1", fontWeight: "bold" }}>
            ₡ {product?.price}
          </h2>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#0691d1",
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ color: "#FFFFFF", fontSize: 30 }}>Nuevos Productos</h2>
      </div>
    </div>
  );
};

export default Product;

const tutorialSteps = [
  {
    label: "San Francisco – Oakland Bay Bridge, United States",
    imgPath: "https://nabetse-cloud.s3.amazonaws.com/Publi%2B/apartment.jpg",
  },
  {
    label: "Bird",
    imgPath:
      "https://nabetse-cloud.s3.amazonaws.com/Publi%2B/harley-davidson.jpg",
  },
  {
    label: "Bali, Indonesia",
    imgPath:
      "https://nabetse-cloud.s3.amazonaws.com/Publi%2B/nike-air-jordan.jpg",
  },
  {
    label: "NeONBRAND Digital Marketing, Las Vegas, United States",
    imgPath: "https://nabetse-cloud.s3.amazonaws.com/Publi%2B/playstation5.jpg",
  },
  {
    label: "Goč, Serbia",
    imgPath: "https://nabetse-cloud.s3.amazonaws.com/Publi%2B/riu-palace.jpg",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minWidth: 260,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    overflow: "hidden",
    display: "block",
    objectFit: "contain",
    height: 500,
    width: "auto",
  },
}));

export function TextMobileStepper() {
  const classes = useStyles();
  // const theme = useTheme();
  const location = useLocation<{ id: number }>();
  const [product, setRaffleProduct] = React.useState<any>(undefined);

  React.useEffect(() => {
    if (location && location.state && location.state.id) {
      const index = productsInfo.findIndex(
        (product) => product.id === location.state.id
      );
      const product = productsInfo[index];
      setRaffleProduct(product);
    }
  }, [location]);

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      {/* <Paper square elevation={0} className={classes.header}>
        <Typography>{tutorialSteps[activeStep].label}</Typography>
      </Paper> */}
      <img
        className={classes.img}
        src={(product && product.image) || ""}
        alt={tutorialSteps[activeStep].label}
      />
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {`>`}
            {/* {theme.direction === 'rtl' ? <h2>Back</h2> : <h2>Next</h2>} */}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {/* {theme.direction === 'rtl' ? <h2>Back</h2> : <h2>Next</h2>} */}
            {"<"}
          </Button>
        }
      />
    </div>
  );
}
