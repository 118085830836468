import React from "react";
import BranchOfficeInformation from "./BranchOfficeInformation";
import Carousel from "../../../share/components/Carousel/Carousel";
import MapContainer from "../../../share/components/Map/Map";

const imagesUrl = [
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-01.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-02.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-03.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-04.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-05.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-06.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-07.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-08.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-09.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-10.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-11.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-12.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-13.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-14.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-15.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-16.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-17.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-18.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/ferreteria-buenos-aires/ferreteria-buenos-aires-19.jpg",
];

const SalaDeCeramicaYAcabados = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <div>
      <div className="branch-information-title-container">
        <img
          alt="values"
          src="https://msc-images-prod.s3.amazonaws.com/images/sucursales/buenos-aires.png"
          className="branch-office-image-title-01"
        />
      </div>
      <Carousel imagesUrl={imagesUrl} />
      <BranchOfficeInformation
        schedule="Jornada continua de Lunes a Viernes de 7:00a.m. a 5:00p.m. y los Sábados de 7:00a.m. a 12:00m.d."
        address="150 mts Oeste de la Plaza de Deportes de Buenos Aires."
        telephone="2453-1553"
        whatsapp1="6296-3148"
      />
      <MapContainer
        locationUrl="https://www.google.com/maps/place/Ferreter%C3%ADa+Maderas+San+Carlos+-+Palmares/@10.0680403,-84.4391921,17z/data=!3m1!4b1!4m5!3m4!1s0x8fa05ad2a1e0e287:0xa1478cb933783da1!8m2!3d10.0680497!4d-84.436986?hl=es"
        location={{
          lat: 10.06802,
          lng: -84.4369999,
        }}
      />
    </div>
  );
};

export default SalaDeCeramicaYAcabados;
