import React, { useEffect, useState, FC } from "react";
import {
  CarouselProvider,
  ButtonBack,
  Slider,
  Slide,
  ButtonNext,
} from "pure-react-carousel";

interface ICarouselProps {
  imagesUrl: string[];
}

const Carousel: FC<ICarouselProps> = ({ imagesUrl }) => {
  const [visibleSlides, setVisibleSlides] = useState(0);

  useEffect(() => {
    if (window.screen.width > 1024) {
      setVisibleSlides(3);
    } else if (window.screen.width > 768) {
      setVisibleSlides(2);
    } else {
      setVisibleSlides(1);
    }
  }, []);

  return (
    <div className="home-carousel-2">
      <CarouselProvider
        naturalSlideWidth={100}
        interval={2000}
        naturalSlideHeight={100}
        dragEnabled={false}
        visibleSlides={visibleSlides}
        totalSlides={imagesUrl.length}
        touchEnabled={false}
        isPlaying={true}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="home-carousel-2-control-btn-left">
            <ButtonBack className="history-slider-button">
              <h2 style={{ color: "#9DA1A6", fontWeight: "bold" }}>{"<"}</h2>
            </ButtonBack>
          </div>

          <Slider className="home-carousel-sliders-history">
            {imagesUrl.map((imageUrl, index) => (
              <Slide key={index} index={index}>
                <img
                  src={imageUrl}
                  alt={`history-${index}`}
                  style={{
                    objectFit: "cover",
                    height: "100%",
                    width: "100%",
                    padding: 40,
                  }}
                  className="home-carousel-2-img"
                />
              </Slide>
            ))}
          </Slider>
          <div className="home-carousel-2-control-btn-right">
            <ButtonNext className="history-slider-button">
              <h2 style={{ color: "#9DA1A6", fontWeight: "bold" }}>{">"}</h2>
            </ButtonNext>
          </div>
        </div>
      </CarouselProvider>
    </div>
  );
};

export default Carousel;
