import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
// import { Marker } from "./Marker";
import "./styles.scss";

const mapStyles = {
  width: "100%",
  height: "300px",
  cursor: "none",
};

// interface IMapProps {
//   location?: {
//     lat: number;
//     lng: number;
//   };
//   locationUrl: string;
//   google?: any;
// }

const MapContainer = (props: any) => {
  console.log("props", props);

  const onClickMarker = () => {
    window.open(props.locationUrl);
  };

  return (
    <div className="map" style={{ height: 300 }}>
      <Map
        google={props.google}
        style={mapStyles}
        initialCenter={props.location}
        // @ts-ignore
        zoom={19}
        // draggable={false}
      >
        <Marker onClick={onClickMarker} />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCUDlQ_TNP29tJgbre1_1Ik5XvJ-6eB5AY",
  LoadingContainer: null,
})(MapContainer);
