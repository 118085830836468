import { IRouteAuth } from "../../Routing/types";
import { UserTypes } from "../types/auth";

export const validateAuthentication = (userType: UserTypes, policies: UserTypes[]): IRouteAuth => {
  if (policies.length === 0) {
    return {
      authorized: true,
    };
  }
  const isAuthenticated = userType !== UserTypes.UNAUTH;
  const authorized = !!policies.find((policy) => policy === userType);
  return {
    authorized,
    ...isAuthenticated && !authorized ? { forbidden: true } : {},
  };
}