import { UserTypes } from "../Auth/types/auth";

export enum RoutesModules {
  BRANCH_OFFICES = 'Sucursales',
  COMPANY = "Quienes Somos",
  CONTACT = "Contáctenos",
  JOBS = "Unete a nuestro equipo",
  QUOTATIONS = "Cotizaciones",
  ROOT = "Inicio",
  PRODUCTS = "Productos",
  PROMOTIONS = "Promociones",
  COMPRAS = "Compras"
}

export interface IRouteModule {
  routes: IRoutes[];
  name: RoutesModules;
}

export interface IRouteAuth {
  authorized: boolean;
  forbidden?: boolean;
}

export interface ILinkedRoute {
  key: string;
  component: ComponentType;
  path: string[];
  policies: UserTypes[];
  module: string;
}

// Check this native component type ****
export type ComponentType = React.ElementType | React.ComponentType;

export interface IRoutes {
  paths: string[];
  component: ComponentType;
  policies: UserTypes[];
}

export type RoutePathsFn = (UserTypes?: UserTypes) => IRoutes[];