import routes from '../routes';
import Company from '../../Company/Company';
import { RoutePathsFn, IRouteModule, RoutesModules } from '../types';

const moduleRoutes: RoutePathsFn = () => ([
  {
    paths: [routes.COMPANY, routes.ROOT],
    component: Company,
    policies: []
  }
]);

export const companyModule = (): IRouteModule => ({
  name: RoutesModules.COMPANY,
  routes: moduleRoutes()
});