import * as React from 'react';
import { RouteChildrenProps, Redirect } from 'react-router';
import { useAuthValidator } from '../../Auth/hooks/useAuthValidator';
import { ILinkedRoute } from './../types'
import Routes from '../routes';
import Home from '../../Home/Home';

export interface IAuthRoute {
  linkedRoute: ILinkedRoute;
  match: RouteChildrenProps<any, any>;
}
const AuthRoute = ({ linkedRoute, match }: IAuthRoute) => {
  const { component: Component, policies } = linkedRoute;
  const { auth } = useAuthValidator(policies);
  if (auth.authorized) {
    return <Component {...match} />
  }
  if (auth.forbidden) {
    return <Redirect to={Routes.FORBIDDEN} />
  }
  return <Home />
}

export default React.memo(AuthRoute);