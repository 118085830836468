import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Routes from "../../Routing/routes";
import { useHistory } from "react-router";
import "./styles/navbar.styles.scss";

const active = {
  margin: 0,
  letterSpacing: 2,
  color: "#494949",
  fontWeight: 400,
  borderBottom: "5px solid #F8EB24",
  paddingBottom: 10,
  cursor: "pointer",
  fontSize: 20,
  marginLeft: 20,
  marginRight: 20,
};

const desactive = {
  margin: 0,
  letterSpacing: 2,
  color: "#9DA1A6",
  fontWeight: 400,
  paddingBottom: 15,
  cursor: "pointer",
  fontSize: 20,
  marginLeft: 20,
  marginRight: 20,
};

const NavigationBar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const history = useHistory();
  return (
    <div>
      <Navbar
        light
        expand="lg"
        style={{
          paddingTop: 30,
          paddingBottom: 30,
          flex: 1,
          backgroundColor: "rgb(241, 241, 241)",
        }}
      >
        <NavbarBrand href={Routes.ROOT}>
          <img
            src="https://msc-images-prod.s3.amazonaws.com/logo-nav.png"
            style={{ height: 80, width: "auto" }}
            className="navigation-msc-logo"
            alt="Ferretería Maderas San Carlos"
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse
          isOpen={isOpen}
          navbar
          style={{ backgroundColor: "rgb(241, 241, 241)", zIndex: 1 }}
        >
          <Nav
            className="navigation-collapse"
            style={{
              display: "flex",
              paddingTop: 20,
              alignItems: "center",
              justifyContent: "flex-start",
            }}
            navbar
          >
            <NavItem>
              <NavLink
                active={history.location.pathname === Routes.COMPANY}
                href={Routes.COMPANY}
              >
                <h2
                  style={
                    history.location.pathname === "/quienes-somos" ||
                    history.location.pathname === "/"
                      ? active
                      : desactive
                  }
                  className="navigation-link"
                >
                  ¿Quiénes somos?
                </h2>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={history.location.pathname === Routes.BRANCH_OFFICES}
                href={Routes.BRANCH_OFFICES}
              >
                <h2
                  style={
                    history.location.pathname === Routes.BRANCH_OFFICES
                      ? active
                      : desactive
                  }
                  className="navigation-link"
                >
                  Sucursales
                </h2>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={history.location.pathname === Routes.QUOTATIONS}
                href={Routes.QUOTATIONS}
              >
                <h2
                  style={
                    history.location.pathname === Routes.QUOTATIONS
                      ? active
                      : desactive
                  }
                  className="navigation-link"
                >
                  Cotizaciones
                </h2>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                active={history.location.pathname === Routes.PRODUCTS}
                href={Routes.PRODUCTS}
              >
                <h2
                  style={
                    history.location.pathname === Routes.PRODUCTS
                      ? active
                      : desactive
                  }
                  className="navigation-link"
                >
                  Productos
                </h2>
              </NavLink>
            </NavItem> */}

            {/* <NavItem>
              <NavLink
                active={history.location.pathname === Routes.PROMOTIONS}
                href={Routes.PROMOTIONS}
              >
                <h2
                  style={
                    history.location.pathname === Routes.PROMOTIONS
                      ? active
                      : desactive
                  }
                  className="navigation-link"
                >
                  Promociones
                </h2>
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink
                active={history.location.pathname === Routes.JOBS}
                href={Routes.JOBS}
              >
                <h2
                  style={
                    history.location.pathname === "/unete-a-nuestro-equipo"
                      ? active
                      : desactive
                  }
                  className="navigation-link"
                >
                  ¡Únete a nuestro equipo!
                </h2>
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink
                active={history.location.pathname === Routes.COMPRAS}
                href={Routes.COMPRAS}
              >
                <img
                  src="https://msc-images-prod.s3.amazonaws.com/images/cart.png"
                  style={{
                    height: 35,
                    marginBottom: 18,
                    width: "auto",
                    color: "red",
                  }}
                  alt="Ferretería Maderas San Carlos"
                />
              </NavLink>
            </NavItem> */}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default NavigationBar;
