import React from "react";
import BranchOfficeInformation from "./BranchOfficeInformation";
import Carousel from "../../../share/components/Carousel/Carousel";
import MapContainer from "../../../share/components/Map/Map";

const imagesUrl = [
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-01.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-02.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-03.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-04.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-05.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-06.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-07.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-08.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-09.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-10.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-11.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-12.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-13.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-14.jpg",
  "https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero/centro-mueblero-15.jpg",
];

const CentroMueblero = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <div>
      <div className="branch-information-title-container">
        <img
          alt="values"
          src="https://msc-images-prod.s3.amazonaws.com/images/sucursales/centro-mueblero.png"
          className="branch-office-image-title-01"
        />
      </div>
      <Carousel imagesUrl={imagesUrl} />
      <BranchOfficeInformation
        schedule="De Lunes a Viernes de 7:00a.m. a 12:00m.d. y de 1:00p.m. a 5:00p.m. y los Sábados de 7:00a.m. a 12:00m.d."
        address="200 mts Sur del BCR en Palmares Centro."
        telephone="2453-1919"
        whatsapp1="6280-2013"
        whatsapp2="8582-4694"
      />
      <MapContainer
        locationUrl="https://www.google.com/maps/place/Centro+Mueblero+-+Maderas+San+Carlos+Palmares/@10.0540378,-84.4315166,19z/data=!4m21!1m15!4m14!1m6!1m2!1s0x8fa0f9db3ac74b7d:0x2767c19c1a5fbe02!2sParque+Palmares,+Provincia+de+Alajuela,+Alajuela!2m2!1d-84.2140534!2d10.0200407!1m6!1m2!1s0x8fa05ad2a1e0e287:0xa1478cb933783da1!2sFerreter%C3%ADa+Maderas+San+Carlos+-+Palmares,+Provincia+de+Alajuela,+Palmares!2m2!1d-84.436986!2d10.0680497!3m4!1s0x8fa05ab65ec52f59:0x55216ed8fc6b0464!8m2!3d10.0538505!4d-84.4314303?hl=es"
        location={{
          lat: 10.0538429,
          lng: -84.4314308,
        }}
      />
    </div>
  );
};

export default CentroMueblero;
