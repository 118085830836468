import routes from '../routes';
import Quotations from '../../Quotations/Quotations';
import { RoutePathsFn, IRouteModule, RoutesModules } from '../types';

const moduleRoutes: RoutePathsFn = () => ([
  {
    paths: [routes.QUOTATIONS],
    component: Quotations,
    policies: []
  }
]);

export const quotationsModule = (): IRouteModule => ({
  name: RoutesModules.QUOTATIONS,
  routes: moduleRoutes()
});