import routes from '../routes';
import { RoutePathsFn, IRouteModule, RoutesModules } from '../types';
import Compras from '../../Compras/Compras';

const moduleRoutes: RoutePathsFn = () => ([
  {
    paths: [routes.COMPRAS],
    component: Compras,
    policies: []
  }
]);

export const comprasModule = (): IRouteModule => ({
  name: RoutesModules.COMPRAS,
  routes: moduleRoutes()
});