import React, { useEffect, useRef } from "react";
import "./compras.scss";

const Compras = () => {
  const frameRef = useRef(null);

  useEffect(() => {
    if (frameRef.current) {
      console.log("frameRef", frameRef);
      // frameRef.current.
    }
  }, [frameRef]);

  return (
    <div className="compras-container">
      <iframe
        src="https://pcmsc.itsales.online/"
        title="MSC e-commerce"
        frameBorder="0"
        ref={frameRef}
        // scrolling="no"
      />
    </div>
  );
};

export default Compras;
