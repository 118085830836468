import * as React from "react";
import userAuthenticationReducer from "../state/userAuthenticationReducer";
import { IAuthState, defaultAuthPayload } from "../types/auth";
import { login } from "./actions/login";
import { logout } from "./actions/logout";

interface IUserAuthActionsContext {
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
}

export type UserAuthenticationContextType = {
  state: IAuthState;
  actions: IUserAuthActionsContext;
};

const UserAuthenticationContext = React.createContext({} as UserAuthenticationContextType);

const UserAuthenticationProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(userAuthenticationReducer, defaultAuthPayload);
  const value: UserAuthenticationContextType = {
    state,
    actions: {
      login: login(dispatch),
      logout: logout(dispatch)
    }
  };
  return (
    <UserAuthenticationContext.Provider value={value}>
      {children}
    </UserAuthenticationContext.Provider>
  );
}

export {
  UserAuthenticationContext,
  UserAuthenticationProvider
};