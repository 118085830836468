import React from "react";

const Promotions = () => {
  return (
    <div>
      <div
        style={{
          backgroundColor: "#0691d1",
          height: "85vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ color: "#FFFFFF", fontSize: 30 }}>Banner Promoción</h2>
      </div>
    </div>
  );
};

export default Promotions;
