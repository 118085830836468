import React, { useState } from "react";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import quienesSomos from "../../share/images/quienes-somos.png";
import queVendemos from "../../share/images/que-vendemos.png";
import "./company.css";

const Company = () => {
  const [promotionImage, setPromotionImage] = useState<boolean>(true);
  const [promotionImage2, setPromotionImage2] = useState<boolean>(true);
  const [promotionImage3, setPromotionImage3] = useState<boolean>(true);
  const [promotionImage4, setPromotionImage4] = useState<boolean>(true);
  const [promotionImage5, setPromotionImage5] = useState<boolean>(true);
  const [promotionImage6, setPromotionImage6] = useState<boolean>(true);

  const handlePromotionImageLoadError = (e: any) => {
    setPromotionImage(false);
  };

  const handlePromotionImageLoadError2 = (e: any) => {
    setPromotionImage2(false);
  };

  const handlePromotionImageLoadError3 = (e: any) => {
    setPromotionImage3(false);
  };

  const handlePromotionImageLoadError4 = (e: any) => {
    setPromotionImage4(false);
  };

  const handlePromotionImageLoadError5 = (e: any) => {
    setPromotionImage5(false);
  };
  const handlePromotionImageLoadError6 = (e: any) => {
    setPromotionImage6(false);
  };

  return (
    <div>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={58}
        // totalSlides={carouselImages.length}
        totalSlides={
          [
            promotionImage,
            promotionImage2,
            promotionImage3,
            promotionImage4,
            promotionImage5,
            promotionImage6,
          ].filter((p) => p).length + 1
        }
        isPlaying={true}
        dragEnabled={false}
        interval={4000}
        touchEnabled={false}
      >
        <Slider>
          {promotionImage && (
            <Slide className="slide-cotizacion" index={0}>
              <div>
                <img
                  alt="promocion"
                  onError={handlePromotionImageLoadError}
                  src="https://msc-images-prod.s3.amazonaws.com/images/home/promocion.png"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </Slide>
          )}
          {promotionImage2 && (
            <Slide className="slide-cotizacion" index={1}>
              <div>
                <img
                  alt="promocion"
                  onError={handlePromotionImageLoadError2}
                  src="https://msc-images-prod.s3.amazonaws.com/images/home/promocion2.png"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </Slide>
          )}
          {promotionImage3 && (
            <Slide className="slide-cotizacion" index={2}>
              <div>
                <img
                  alt="promocion"
                  onError={handlePromotionImageLoadError3}
                  src="https://msc-images-prod.s3.amazonaws.com/images/home/promocion3.png"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </Slide>
          )}
          {promotionImage4 && (
            <Slide className="slide-cotizacion" index={3}>
              <div>
                <img
                  alt="promocion"
                  onError={handlePromotionImageLoadError4}
                  src="https://msc-images-prod.s3.amazonaws.com/images/home/promocion4.png"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </Slide>
          )}
          {promotionImage5 && (
            <Slide className="slide-cotizacion" index={4}>
              <div>
                <img
                  alt="promocion"
                  onError={handlePromotionImageLoadError5}
                  src="https://msc-images-prod.s3.amazonaws.com/images/home/promocion5.png"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </Slide>
          )}
          {promotionImage6 && (
            <Slide className="slide-cotizacion" index={5}>
              <div>
                <img
                  alt="promocion"
                  onError={handlePromotionImageLoadError6}
                  src="https://msc-images-prod.s3.amazonaws.com/images/home/promocion6.png"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </Slide>
          )}
          <Slide
            className="slide-cotizacion"
            index={
              [
                promotionImage,
                promotionImage2,
                promotionImage3,
                promotionImage4,
                promotionImage5,
                promotionImage6,
              ].filter((p) => p).length + 1
            }
          >
            <div>
              <video
                autoPlay={true}
                loop={true}
                muted
                style={{ height: "100%", width: "100%" }}
                playsInline={true}
              >
                <source
                  src="https://msc-images-prod.s3.amazonaws.com/videos/home/intro-new.mp4"
                  type="video/mp4"
                ></source>
              </video>
            </div>
          </Slide>
        </Slider>
      </CarouselProvider>

      <div className="quienes-somos-container">
        <div className="quienes-somos-image-container">
          <img
            className="home-01"
            alt="home-01"
            src="https://msc-images-prod.s3.amazonaws.com/images/home/quienes-somos.jpg"
          />
        </div>
        <div className="quienes-somos-information-container">
          <img
            alt="quienes-somos"
            src={quienesSomos}
            className="quienes-somos-title-image"
          />
          <p className="quienes-somos-information">
            Ferretería Maderas San Carlos - Palmares
          </p>
          <p className="quienes-somos-information">
            Trabajamos en cumplir sus sueños y proyectos de vida, brindando
            soluciones en productos de ferretería, materiales de construcción y
            acabados, a través de nuestra asesoría y excelente servicio al
            cliente, con el respaldo y confianza de más de 30 años de
            experiencia.
          </p>
          <p className="quienes-somos-information">
            <span style={{ fontWeight: 800, color: "#282626", opacity: 0.7 }}>
              Valores:{" "}
            </span>
            Servicio, honestidad, trabajo en equipo, compromiso y respeto.
          </p>
          {/* <p className="quienes-somos-information">
            Nos especializamos en la venta de materiales de Ferretería,
            Construcción y Acabados. Contamos con 4 locales en el cantón de
            Palmares.
          </p> */}
        </div>
      </div>

      {/* <div className="values-container"> */}
      {/* <div className="value-container">
          <div className="value-header">
            <img
              src="https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/icono01.png"
              className="value-img"
            />
            <img alt="values" src={mision} className="values" />
          </div>
          <p className="value-description">
            Brindar un servicio de alta calidad, ofreciendo a nuestros clientes
            variedad de productos, a precios competitivos y con una excelente
            atención al cliente.
          </p>
        </div> */}
      {/* <div className="value-container">
          <div className="value-header">
            <img
              src="https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/icono02.png"
              className="value-img"
            />
            <img alt="values" src={vision} className="values" />
          </div>
          <p className="value-description">
            Ser una empresa líder en la zona de Occidente; en la venta de
            materiales de cons trucción, ferretería y acabados que permitan
            satisfacer las necesidades e intereses de sus clientes.
          </p>
        </div> */}
      {/* <div className="value-container">
          <div className="value-header">
            <div>
              <img
                src="https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/icono03.png"
                className="value-img"
              />
            </div>
            <img alt="values" src={valores} className="values" />
          </div>
          <p className="value-description">
            Servicio, honestidad, trabajo en equipo, compromiso y respeto.
          </p>
        </div>
      </div> */}
      <div className="que-vendemos">
        <img
          alt="que-vendemos-title"
          src={queVendemos}
          className="que-vendemos-title-image"
        />
        <div className="que-vendemos-container">
          <p className="que-vendemos-label">Materiales de construcción</p>
          <p className="que-vendemos-label">PVC</p>
          <p className="que-vendemos-label">Agregados</p>
          <p className="que-vendemos-label">Ferretería</p>
          <p className="que-vendemos-label">Herramientas</p>
          <p className="que-vendemos-label">Cerrajería</p>
          <p className="que-vendemos-label">Maderas de pino</p>
          <p className="que-vendemos-label">Plywood</p>
          <p className="que-vendemos-label">Láminas</p>
          <p className="que-vendemos-label">Cerámica</p>
          <p className="que-vendemos-label">Acabados</p>
          <p className="que-vendemos-label">Iluminación</p>
          <p className="que-vendemos-label">Loza sanitaria</p>
          <p className="que-vendemos-label">Grifería</p>
          <p className="que-vendemos-label">Diseño de interiores</p>
        </div>
      </div>
    </div>
  );
};

export default Company;
