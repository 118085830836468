import { ILinkedRoute, IRouteModule } from '../types'

export const linkRoutes = (modules: IRouteModule[]): ILinkedRoute[] => {
  return modules.reduce((linkedRoutes, module) => ([
    ...linkedRoutes, ...getModuleRoutes(module)
  ]), [] as ILinkedRoute[])
}
const getModuleRoutes = (module: IRouteModule) => {
  return module.routes.map((route, routeKey) => ({
    module: module.name,
    component: route.component,
    path: route.paths,
    policies: route.policies,
    key: module.name + routeKey
  }))
}