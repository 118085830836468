import * as React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>404</h1>
      <h3>Page Not Found</h3>
    </div>
  );
}

export default NotFound;
