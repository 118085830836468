const storage = window.localStorage;

const storageManager = {
  save: (key: string, object: any) =>
    storage.setItem(key, JSON.stringify(object)),
  get: (key: string) => {
    const storedItem = storage.getItem(key);
    return storedItem ? JSON.parse(storedItem) : null;
  },
  remove: (key: string) => storage.removeItem(key),
  clear: () => storage.clear()
};

export default storageManager;
