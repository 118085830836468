import React, { ReactElement, FC, ReactNode } from "react";
import "./modal.css";

interface Props {
  children: ReactNode;
  show: boolean;
  cancelCallback?: Function;
  wa?: boolean;
}

const ModalComponent: FC<Props> = ({
  children,
  show,
  cancelCallback,
  wa
}): ReactElement => {
  if (!show) {
    return <></>;
  }

  return (
    <>
      <div
        className="overlay-modal"
        onClick={() => {
          cancelCallback?.();
        }}
      ></div>
      <div className="wrapper-modal">
        <div
          className="main-modal"
          style={{ ...(wa ? { marginTop: 80 } : {}) }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default ModalComponent;
