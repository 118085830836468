import routes from '../routes';
import { RoutePathsFn, IRouteModule, RoutesModules } from '../types';
import Jobs from '../../Jobs/Jobs';

const moduleRoutes: RoutePathsFn = () => ([
  {
    paths: [routes.JOBS],
    component: Jobs,
    policies: []
  }
]);

export const jobsModule = (): IRouteModule => ({
  name: RoutesModules.JOBS,
  routes: moduleRoutes()
});