import * as React from 'react';
import { ILinkedRoute } from '../../types';
import { RouteModuleContext } from '../../context/RouteModule';
import { linkRoutes } from '../../helpers/linkRoutes';
import { UserTypes } from '../../../Auth/types/auth';

export const useRoutes = (): [ILinkedRoute[], UserTypes] => {
  const [routeModules, userType] = React.useContext(RouteModuleContext)
  const routes = React.useMemo(() => linkRoutes(routeModules), [routeModules])
  return [routes, userType];
}
