import React from "react";

const Jobs = () => {
  return (
    <div>
      {/* <img src="https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/unete-a-nosotros.png" style={{ width: '100%', height: 'auto' }} /> */}
      <div
        style={{
          paddingBottom: 80,
          marginTop: 40,
          paddingTop: 40,
          backgroundColor: "#FFFFFF",
        }}
      >
        <div
          style={{
            height: 600,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#0691d1",
              width: "100%",
              marginLeft: "10%",
              marginRight: "10%",
              padding: 40,
              borderRadius: 25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <input
              type="text"
              placeholder="Nombre"
              style={{
                backgroundColor: "#FFFFFF",
                margin: 0,
                fontSize: 20,
                height: 50,
                border: 0,
                borderRadius: 10,
                paddingLeft: 20,
                paddingRight: 20,
                marginBottom: 20,
                color: "#9DA1A6",
                width: "100%",
              }}
            />
            <input
              type="text"
              placeholder="Correo"
              style={{
                backgroundColor: "#FFFFFF",
                margin: 0,
                fontSize: 20,
                height: 50,
                border: 0,
                borderRadius: 10,
                paddingLeft: 20,
                paddingRight: 20,
                marginBottom: 20,
                color: "#9DA1A6",
                width: "100%",
              }}
            />
            <input
              type="text"
              placeholder="Teléfono"
              style={{
                backgroundColor: "#FFFFFF",
                margin: 0,
                fontSize: 20,
                height: 50,
                border: 0,
                borderRadius: 10,
                paddingLeft: 20,
                paddingRight: 20,
                marginBottom: 20,
                color: "#9DA1A6",
                width: "100%",
              }}
            />
            <input
              type="text-area"
              placeholder="Experiencia"
              style={{
                backgroundColor: "#FFFFFF",
                margin: 0,
                fontSize: 20,
                height: 100,
                border: 0,
                borderRadius: 10,
                paddingLeft: 20,
                paddingRight: 20,
                marginBottom: 20,
                color: "#9DA1A6",
                width: "100%",
              }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <img src="https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/boton-enviar.png" style={{ width: 170, height: 50, borderRadius: 10 }} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
