export enum UserTypes {
  UNAUTH,
  ADMIN,
  CASEMANAGER,
  LAWENFORCEMENTAGENT
}

export interface IAuthState {
  readonly accessToken: IToken | null;
  readonly agencyCode: string;
  readonly errorMessage: string;
  readonly idToken: IToken | null;
  readonly lastLogin: number | null;
  readonly loading: boolean;
  readonly refreshToken: IRefreshToken | null;
  readonly username: string;
  readonly userType: UserTypes;
}

export interface IAuthActionsPayload {
  accessToken: IToken | null;
  agencyCode: string;
  errorMessage: string;
  idToken: IToken | null;
  lastLogin: number | null;
  loading: boolean;
  refreshToken: IRefreshToken | null;
  username: string;
  userType: UserTypes
}

export interface IToken {
  readonly jwtToken: string;
  readonly payload: IAuthActionsPayload;
}

export interface IRefreshToken {
  readonly token: string;
}

export enum AuthTypes {
  login = "LOGIN",
  logout = "LOGOUT",
  logInLoading = 'LOGINLOADING',
}

export interface IAuthAction {
  type: AuthTypes;
  payload: IAuthActionsPayload;
}

export const defaultAuthPayload: IAuthActionsPayload = {
  username: '',
  idToken: null,
  accessToken: null,
  refreshToken: null,
  errorMessage: '',
  loading: false,
  userType: UserTypes.UNAUTH,
  agencyCode: '',
  lastLogin: null
}