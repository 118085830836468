import React, { FC } from "react";
import "./WhatsappButton.css";

interface ButtonStyles {
  size?: number;
  isSection?: boolean;
}

export const openWhatsAppInNewTab = () => {
  const win = window.open("https://api.whatsapp.com/send?phone=50662963093");
  win && win.focus();
};

const WhatsappButton: FC<ButtonStyles> = ({ size = 1, isSection }) => {
  return (
    <div
      onClick={openWhatsAppInNewTab}
      className={`wa-wrapper ${isSection && "wa-wrapper-section"}`}
    >
      <div className="wa">
        <span
          className="wa-first-word"
          style={{ fontSize: 22 * size, margin: `${-5 * size}px 0` }}
        >
          Hablemos
        </span>
        <span
          className="wa-second-word"
          style={{ fontSize: 16 * size, margin: `${-5 * size}px 0` }}
        >
          por Whatsapp
        </span>
      </div>
    </div>
  );
};

export default WhatsappButton;
