import { IAuthState, IAuthAction, AuthTypes, defaultAuthPayload } from "../types/auth";

const userAuthenticationReducer = (state: IAuthState, action: IAuthAction) => {
  switch (action.type) {
    case AuthTypes.login:
      return action.payload;
    case AuthTypes.logInLoading:
      return { ...state, loading: true };
    case AuthTypes.logout:
      return defaultAuthPayload;
  }
  return state;
}

export default userAuthenticationReducer;
