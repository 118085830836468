import routes from '../routes';
import { RoutePathsFn, IRouteModule, RoutesModules } from '../types';
import Products from '../../Products/Products';
import Product from '../../Product/Product';

const moduleRoutes: RoutePathsFn = () => ([
  {
    paths: [routes.PRODUCTS],
    component: Products,
    policies: []
  },
  {
    paths: [routes.PRODUCT],
    component: Product,
    policies: []
  }
]);

export const productsModule = (): IRouteModule => ({
  name: RoutesModules.PRODUCTS,
  routes: moduleRoutes()
});