import React from "react";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import logo from "../../logo.png";

const Home = () => {
  return (
    <div>
      <div style={{ flex: 1 }}>
        <ReactPlayer
          height="auto"
          width={"100%"}
          playing={true}
          loop={true}
          url="https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Videos/intro.mp4"
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginTop: "6rem",
          marginBottom: "6rem"
        }}
      >
        <div style={{ width: "50%" }}>
          <img
            style={{ width: "100%" }}
            alt="home-01"
            src="https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/home-image-01.png"
          />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            width: "50%",
            flexDirection: "column",
            paddingLeft: 30,
            paddingRight: 30
          }}
        >
          <h2 style={{ fontSize: 10 }}>QUIENES SOMOS?</h2>
          <p style={{ fontSize: 10 }}>
            Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
            in laying out print, graphic or web designs. The passage is
            attributed to an unknown typesetter in the 15th century who is
            thought to have scrambled parts of Cicero's De Finibus Bonorum et
            Malorum for use in a type specimen book.
          </p>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          width: "100%",
          padding: 40,
          paddingTop: 0
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1
          }}
        >
          <img
            style={{ width: "50%" }}
            alt="mision"
            src="https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/mision.png"
          />
          <h3 style={{ fontSize: 14, marginTop: 20 }}>Mision</h3>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1
          }}
        >
          <img
            style={{ width: "50%" }}
            alt="vision"
            src="https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/mision.png"
          />
          <h3 style={{ fontSize: 14, marginTop: 20 }}>Vision</h3>
          <p style={{ fontSize: 10 }}>
            Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
            in laying out print, graphic or web designs. The passage is
            attributed to an unknown typesetter in the 15th century who is
            thought to have scrambled parts of Cicero's De Finibus Bonorum et
            Malorum for use in a type specimen book.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1
          }}
        >
          <img
            style={{ width: "50%" }}
            alt="test"
            src="https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/mision.png"
          />
          <h3 style={{ fontSize: 14, marginTop: 20 }}>Valores</h3>
          <p style={{ fontSize: 10 }}>
            Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
            in laying out print, graphic or web designs. The passage is
            attributed to an unknown typesetter in the 15th century who is
            thought to have scrambled parts of Cicero's De Finibus Bonorum et
            Malorum for use in a type specimen book.
          </p>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#008ACC",
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: 40,
          paddingBottom: 160
        }}
      >
        <div>
          <h1 style={{ color: "white", fontSize: 20 }}>NUESTRA</h1>
          <h1 style={{ color: "yellow", fontSize: 20 }}>HISTORIA</h1>
        </div>
        <div style={{ paddingLeft: 40, paddingRight: 40 }}>
          <p style={{ color: "white", fontSize: 14 }}>
            Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
            in laying out print, graphic or web designs. The passage is
            attributed to an unknown typesetter in the 15th century who is
            thought to have scrambled parts of Cicero's De Finibus Bonorum et
            Malorum for use in a type specimen book.
          </p>
        </div>
        <div>
          <p style={{ color: "white", fontSize: 14 }}>
            Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
            in laying out print, graphic or web designs. The passage is
            attributed to an unknown typesetter in the 15th century who is
            thought to have scrambled parts of Cicero's De Finibus Bonorum et
            Malorum for use in a type specimen book.
          </p>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          bottom: 80,
          paddingLeft: 40,
          paddingRight: 40
        }}
      >
        <SingleLineGridList />
      </div>
      <div style={{ padding: 40 }}>
        <h1 style={{ color: "#008ACC" }}>QUE VENDEMOS?</h1>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            width: "100%",
            flexDirection: "row"
          }}
        >
          <h3>Maderas</h3>
          <h3>Banos</h3>
          <h3>Herramientas</h3>
          <h3>Construcion</h3>
          <h3>Hogar</h3>
          <h3>Ceramica</h3>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            width: "100%",
            flexDirection: "row"
          }}
        >
          <h3>Iluminacion</h3>
          <h3>Acabados</h3>
          <h3>Pintura</h3>
          <h3>Decoracion</h3>
          <h3>Maderas</h3>
          <h3>Fontaneria</h3>
          <h3>Jardin</h3>
        </div>
      </div>
      <div style={{ borderWidth: 15, borderColor: "red" }} />
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div>
          <img width="100" src={logo} alt="Ferretería Maderas San Carlos" />
        </div>
        <div>
          <h2>Quienes Somos?</h2>
          <h2>Sucursales</h2>
        </div>
        <div>
          <h2>Cotizaciones</h2>
          <h2>Unete a nuestro equipo!</h2>
        </div>
        <div>
          <h2>Contactenos</h2>
          <h2>6296 3093</h2>
          <h2>cotizaciones1@maderassancarlos.com</h2>
        </div>
        <div>
          <img width="100" src={logo} alt="Ferretería Maderas San Carlos" />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#008ACC",
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          height: 50
        }}
      >
        <h3 style={{ color: "white", fontSize: 20, marginBottom: 0 }}>
          Todos los dererchos reservados 2020
        </h3>
      </div>
    </div>
  );
};

export default Home;

// import React from 'react';

// import tileData from './tileData';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden"
    // backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  }
}));

const tileData = [
  {
    title: "Test1",
    img:
      "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/home-history-01.png"
  },
  {
    title: "Test2",
    img:
      "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/home-history-02.png"
  },
  {
    title: "Test3",
    img:
      "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/home-history-03.png"
  },
  {
    title: "Test4",
    img:
      "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/home-history-01.png"
  },
  {
    title: "Test5",
    img:
      "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/Images/home-history-01.png"
  }
];

const SingleLineGridList = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={3}>
        {tileData.map(tile => (
          <GridListTile
            key={tile.img}
            style={{ paddingRight: 40, paddingLeft: 40, height: 300 }}
          >
            <img src={tile.img} alt={tile.title} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};
