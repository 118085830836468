import * as React from 'react';
import { modules } from '../routesModule';
import { IRouteModule } from '../types';
import { useAuth } from '../../Auth/hooks/useUserAuthentication';
import { UserTypes } from '../../Auth/types/auth';

type RouteModuleContextType = [IRouteModule[], UserTypes, React.Dispatch<React.SetStateAction<IRouteModule[]>>]
const RouteModuleContext = React.createContext([] as any as RouteModuleContextType);

const RouteModuleProvider = ({ children }: any) => {
  const { state: { userType } } = useAuth();
  const getModules = (type: UserTypes) => modules(type);
  const [routeModules, setRouteModules] = React.useState<IRouteModule[]>(getModules(userType));
  React.useEffect(() => {
    setRouteModules(getModules(userType));
  }, [userType]);
  return (
    <RouteModuleContext.Provider value={[routeModules, userType, setRouteModules]}>
      {children}
    </RouteModuleContext.Provider>
  );
}

export {
  RouteModuleContext,
  RouteModuleProvider,
};