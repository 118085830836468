import React, { useState, ChangeEvent } from "react";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import { useAlert } from "react-alert";
import ModalComponent from "../../share/components/Modal/Modal";
import "./quotations.css";

type FormValues = {
  nombre: string;
  correo: string;
  telefono: string;
  detalle: string;
  categoria: string;
};

const Quotations = () => {
  const alert = useAlert();
  const [show, setShow] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    nombre: "",
    correo: "",
    telefono: "",
    detalle: "",
    categoria: "",
  } as FormValues);

  const onSubmit = () => {
    if (!formValues.categoria) {
      alert.error("Por favor seleccione una categoría.");
      return;
    }
    if (!formValues.nombre) {
      alert.error("Por favor agregue su nombre.");
      return;
    }
    if (!formValues.telefono) {
      alert.error("Por favor ingrese su número de teléfono.");
      return;
    }
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = re.test(String(formValues.correo).toLowerCase());
    if (isValid) {
      fetch("https://iwse57ajcd.execute-api.us-east-1.amazonaws.com/dev/send", {
        method: "post",
        body: JSON.stringify({
          email: formValues.correo,
          name: formValues.nombre,
          categoria: formValues.categoria
            ? formValues.categoria.toUpperCase()
            : "",
          telefono: formValues.telefono,
          detalle: formValues.detalle,
        }),
      })
        .then(function (response) {
          setFormValues({
            nombre: "",
            correo: "",
            telefono: "",
            detalle: "",
            categoria: "",
          });
          setShow(true);
        })
        .catch((e) => {
          setShow(false);
          alert.error(
            "Tuvimos un problema a la hora de enviar la cotización, intente de nuevo."
          );
        });
    } else {
      alert.error("Por favor ingrese un correo válido.");
    }
  };
  const setValue = (
    e:
      | ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setFormValues((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  };

  return (
    <div style={{ height: "100%" }} className="quoatations-container">
      <ModalComponent show={show} cancelCallback={setShow}>
        <button className="modal-close-button" onClick={() => setShow(false)}>
          X
        </button>
        <img
          alt="contactenos"
          className="mensaje-enviado-image"
          src="https://msc-images-prod.s3.amazonaws.com/images/cotizaciones/mensaje-enviado.png"
        />
      </ModalComponent>
      <div
        style={{ display: "flex", flexDirection: "column", paddingBottom: 40 }}
      >
        <div className="carousel-quotations">
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={0}
            totalSlides={3}
            isPlaying={true}
            dragEnabled={false}
            interval={4000}
            touchEnabled={false}
          >
            <Slider>
              <Slide className="slide-cotizacion" index={0}>
                <div>
                  <img
                    alt="cotizaciones01"
                    src="https://msc-images-prod.s3.amazonaws.com/images/cotizaciones/cotizaciones01.png"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </Slide>
              <Slide className="slide-cotizacion" index={0}>
                <div>
                  <img
                    alt="cotizaciones02"
                    src="https://msc-images-prod.s3.amazonaws.com/images/cotizaciones/cotizaciones02.png"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </Slide>
              <Slide className="slide-cotizacion" index={0}>
                <div>
                  <img
                    alt="cotizaciones03"
                    src="https://msc-images-prod.s3.amazonaws.com/images/cotizaciones/cotizaciones03.png"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </Slide>
            </Slider>
          </CarouselProvider>
        </div>
        <div className="cotizaciones-form-container">
          <div className="cotizaciones-form-container-image">
            <img
              src="https://msc-images-prod.s3.amazonaws.com/images/cotizaciones/cotizaciones-form.jpg"
              alt="cotizaciones-form"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: 25,
                objectFit: "cover",
              }}
            />
          </div>
          <div
            className="quotations-form"
            style={{
              flex: 2,
              backgroundColor: "#0691d1",
              marginLeft: 40,
              padding: 40,
              borderRadius: 25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              minWidth: 300,
            }}
          >
            <select
              name="categoria"
              id="categoria"
              value={formValues.categoria}
              style={{
                margin: 10,
                fontSize: 20,
                height: 50,
                border: 0,
                borderRadius: 10,
                paddingLeft: 20,
                color: formValues.categoria ? "black" : "#9DA1A6",
                width: "100%",
              }}
              onChange={(e) => setValue(e)}
            >
              <option value="">Seleccione una categoría</option>
              <option value="ferreteria">Ferretería</option>
              <option value="ceramicas">Cerámicas y acabados</option>
              <option value="construccion">Materiales de construcción</option>
              <option value="maderas">Maderas</option>
            </select>
            <input
              onChange={setValue}
              value={formValues.nombre}
              name="nombre"
              type="text"
              placeholder="Nombre"
              style={{
                backgroundColor: "#FFFFFF",
                margin: 10,
                fontSize: 20,
                height: 50,
                border: 0,
                borderRadius: 10,
                padding: 20,
                width: "100%",
              }}
            />
            <input
              onChange={setValue}
              value={formValues.correo}
              name="correo"
              type="text"
              placeholder="Correo"
              style={{
                backgroundColor: "#FFFFFF",
                margin: 10,
                fontSize: 20,
                height: 50,
                border: 0,
                borderRadius: 10,
                padding: 20,
                width: "100%",
              }}
            />
            <input
              onChange={setValue}
              value={formValues.telefono}
              name="telefono"
              type="text"
              placeholder="Teléfono"
              style={{
                backgroundColor: "#FFFFFF",
                margin: 10,
                fontSize: 20,
                height: 50,
                border: 0,
                borderRadius: 10,
                padding: 20,
                width: "100%",
              }}
            />
            <textarea
              onChange={setValue}
              value={formValues.detalle}
              name="detalle"
              placeholder="Detalle"
              style={{
                backgroundColor: "#FFFFFF",
                margin: 10,
                fontSize: 20,
                height: 100,
                border: 0,
                borderRadius: 10,
                padding: 20,
                width: "100%",
              }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <img
                onClick={onSubmit}
                alt="boton-enviar"
                src="https://msc-images-prod.s3.amazonaws.com/images/cotizaciones/boton-enviar.png"
                style={{
                  width: 170,
                  height: 50,
                  borderRadius: 10,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quotations;
