import { IRouteModule } from "./types";
import { UserTypes } from "../Auth/types/auth";
import { companyModule } from "./modules/company";
import { branchOfficesModule } from "./modules/branch-offices";
import { jobsModule } from "./modules/jobs";
import { productsModule } from "./modules/products";
import { quotationsModule } from "./modules/quotations";
import { promotionsModule } from "./modules/promotions";
import { comprasModule } from "./modules/compras";


export const modules = (userType: UserTypes): IRouteModule[] => [
  companyModule(),
  branchOfficesModule(),
  comprasModule(),
  jobsModule(),
  productsModule(),
  quotationsModule(),
  promotionsModule()
]
