import routes from "../routes";
import { RoutePathsFn, IRouteModule, RoutesModules } from "../types";
import BranchOffices from "../../BranchOffices/BranchOffices";
import FerreteriaCentro from "../../BranchOffices/components/FerreteriaCentro";
import CentroMueblero from "../../BranchOffices/components/CentroMueblero";
import SalaDeAcabados from "../../BranchOffices/components/SalaDeAcabados";
import SalaDeCeramicaYAcabados from "../../BranchOffices/components/SalaDeCeramicasYAcabados";

const moduleRoutes: RoutePathsFn = () => [
  {
    paths: [routes.BRANCH_OFFICE_F1],
    component: FerreteriaCentro,
    policies: []
  },
  {
    paths: [routes.BRANCH_OFFICE_F2],
    component: CentroMueblero,
    policies: []
  },
  {
    paths: [routes.BRANCH_OFFICE_F3],
    component: SalaDeAcabados,
    policies: []
  },
  {
    paths: [routes.BRANCH_OFFICE_F4],
    component: SalaDeCeramicaYAcabados,
    policies: []
  },
  {
    paths: [routes.BRANCH_OFFICES],
    component: BranchOffices,
    policies: []
  }
];

export const branchOfficesModule = (): IRouteModule => ({
  name: RoutesModules.BRANCH_OFFICES,
  routes: moduleRoutes()
});
