import * as React from 'react';
import { validateAuthentication } from '../helpers/validateAuthentication';
import { useAuth } from './useUserAuthentication';
import { UserTypes } from '../types/auth';

export const useAuthValidator = (policies: UserTypes[]) => {
  const { state: { userType } } = useAuth();
  const authData = React.useMemo(() => {
    return {
      auth: validateAuthentication(userType, policies)
    }
  }, [userType, policies]);
  return authData;
}