import React from "react";
import { Route, Switch } from "react-router-dom";
import { useRoutes } from "./hooks/routes/useRoutes";
import AuthRoute from "./auth/AuthRoute";
import NotFound from "../../share/components/ErrorPage/NotFoundPage";
import { useAnalytics } from "../../share/hooks/useAnalytics";

const Router = () => {
  const [routes] = useRoutes();
  useAnalytics("UA-187647486-1");

  return (
    <Switch>
      {routes.map((route) => (
        <Route exact={true} key={route.key} path={route.path}>
          {(match) => <AuthRoute match={match} linkedRoute={route} />}
        </Route>
      ))}
      <Route component={NotFound} />
    </Switch>
  );
};

export default React.memo(Router);
