import React from "react";
import "./styles/branch-offices.css";
import { Link } from "react-router-dom";
import Routes from "../Routing/routes";

const BranchOffices = () => {
  return (
    <div className="branch-offices-container">
      <div className="sucursal">
        <Link to={Routes.BRANCH_OFFICE_F1}>
          <img
            src="https://msc-images-prod.s3.amazonaws.com/images/sucursales/suscursal01.jpg"
            alt="suscursal01"
            style={{
              height: "auto",
              width: "100%",
              objectFit: "contain",
            }}
          />
        </Link>
      </div>
      <div className="sucursal">
        <Link to={Routes.BRANCH_OFFICE_F2}>
          <img
            src="https://msc-images-prod.s3.amazonaws.com/images/sucursales/suscursal02.jpg"
            alt="suscursal02"
            style={{
              height: "auto",
              width: "100%",
              objectFit: "contain",
            }}
          />
        </Link>
      </div>
      <div className="sucursal">
        <Link to={Routes.BRANCH_OFFICE_F3}>
          <img
            src="https://msc-images-prod.s3.amazonaws.com/images/sucursales/suscursal03.jpg"
            alt="suscursal03"
            style={{
              height: "auto",
              width: "100%",
              objectFit: "contain",
            }}
          />
        </Link>
      </div>
      <div className="sucursal">
        <Link to={Routes.BRANCH_OFFICE_F4}>
          <img
            src="https://msc-images-prod.s3.amazonaws.com/images/sucursales/suscursal04.jpg"
            alt="suscursal04"
            style={{
              height: "auto",
              width: "100%",
              objectFit: "contain",
            }}
          />
        </Link>
      </div>
    </div>
  );
};

export default BranchOffices;
