import routes from '../routes';
import { RoutePathsFn, IRouteModule, RoutesModules } from '../types';
import Promotions from '../../Promotions/Promotions';

const moduleRoutes: RoutePathsFn = () => ([
  {
    paths: [routes.PROMOTIONS],
    component: Promotions,
    policies: []
  }
]);

export const promotionsModule = (): IRouteModule => ({
  name: RoutesModules.PROMOTIONS,
  routes: moduleRoutes()
});