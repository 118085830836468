import { AuthTypes, UserTypes } from "../../types/auth";

export const login = (dispatch: any) => async (username: string, password: string): Promise<void> => {
  dispatch({ type: AuthTypes.logInLoading, payload: { username, loading: true } });
  try {
    const userType = UserTypes.ADMIN;
    const payload = {
      username,
      errorMessage: '',
      loading: false,
      userType
    };
    dispatch({ type: AuthTypes.login, payload });
  } catch (error) {
    // toastr.error('Login error', 'Login Failed!')
    // storageManager.clear();
    // Error handling
    // dispatch({ ...state, errorMessage: 'Login Failed!' });
  }
}