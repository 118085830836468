import React, { FC } from "react";
// import horario from "../../../share/images/horario.png";
// import direccion from "../../../share/images/direccion.png";
// import whatsapp from "../../../share/images/whatsapp.png";
// import telefono from "../../../share/images/telefono.png";

interface IBranchOfficeInformationProps {
  address: string;
  schedule: string;
  telephone: string;
  whatsapp1: string;
  whatsapp2?: string;
}

const BranchOfficeInformation: FC<IBranchOfficeInformationProps> = ({
  address,
  schedule,
  telephone,
  whatsapp1,
  whatsapp2,
}) => {
  return (
    <div className="branch-information-container">
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingRight: 50,
        }}
      >
        <img
          alt="horario"
          src="https://msc-images-prod.s3.amazonaws.com/images/sucursales/s-horario.png"
          className="branch-office-information-title-image"
        />
        <p className="branch-office-information">{schedule}</p>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingRight: 50,
        }}
      >
        <img
          alt="direccion"
          src="https://msc-images-prod.s3.amazonaws.com/images/sucursales/s-direccion.png"
          className="branch-office-information-title-image"
        />
        <p className="branch-office-information">{address}</p>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingRight: 50,
        }}
      >
        <img
          alt="telefono"
          src="https://msc-images-prod.s3.amazonaws.com/images/sucursales/s-telefono.png"
          className="branch-office-information-title-image"
        />
        <p className="branch-office-information">{telephone}</p>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          alt="whatsapp"
          src="https://msc-images-prod.s3.amazonaws.com/images/sucursales/s-whatsapp.png"
          className="branch-office-information-title-image"
        />
        <p className="branch-office-information" style={{ marginBottom: 0 }}>
          {whatsapp1}
        </p>
        <p className="branch-office-information">{whatsapp2}</p>
      </div>
    </div>
  );
};

export default BranchOfficeInformation;
