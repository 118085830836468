import * as React from "react";
import Router from "./modules/Routing/Router";
import { positions, Provider } from "react-alert";
// @ts-ignore
import AlertTemplate from "react-alert-template-basic";
import { UserAuthenticationProvider } from "./modules/Auth/context/UserAuthentication";
import { RouteModuleProvider } from "./modules/Routing/context/RouteModule";
import NavigationBar from "./modules/Navigation/Navbar/Navbar";
import Footer from "./modules/Navigation/Footer/Footer";
import ModalComponent from "./share/components/Modal/Modal";
import { openWhatsAppInNewTab } from "./share/components/WhatsappButton/WhatsappButton";
import storageManager from "../src/share/utils/localStorage";
import "./App.css";

const oneDayMiliseconds = 86400000;

const options = {
  timeout: 8000,
  position: positions.TOP_RIGHT,
};

const App = () => {
  const [showModal, setShowModal] = React.useState(true);

  const getWA = () => {
    const waModalState = storageManager.get("wa-modal");
    const actualDate = new Date().getTime();
    const showWAModal =
      waModalState && waModalState.closedDate
        ? actualDate - (waModalState && waModalState.closedDate) >
          oneDayMiliseconds
        : true;
    setShowModal(showWAModal);
  };

  const closeWA = () => {
    const closedDate = new Date().getTime();
    storageManager.save("wa-modal", { closedDate });
    setShowModal(false);
  };

  const openWA = () => {
    openWhatsAppInNewTab();
    const closedDate = new Date().getTime();
    storageManager.save("wa-modal", { closedDate });
    setShowModal(false);
  };

  React.useEffect(() => {
    getWA();
  }, []);

  return (
    <div>
      <ModalComponent show={showModal} cancelCallback={closeWA} wa={true}>
        <button className="modal-close-button" onClick={closeWA}>
          X
        </button>
        <img
          alt="contactenos"
          className="whatsapp-modal-image"
          style={{ cursor: "pointer" }}
          src="https://msc-images-prod.s3.amazonaws.com/images/home/wa-modal.png"
          onClick={openWA}
        />
      </ModalComponent>
      <UserAuthenticationProvider>
        <Provider template={AlertTemplate} {...options}>
          <RouteModuleProvider>
            <NavigationBar />
            <Router />
            <Footer />
          </RouteModuleProvider>
        </Provider>
      </UserAuthenticationProvider>
    </div>
  );
};

export default App;
