import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";

import { Chip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Routes from "../Routing/routes";

const useStyles = makeStyles({
  root: {
    margin: 40,
    maxWidth: 345,
  },
  media: {
    height: 250,
    backgroundSize: "contain",
    marginTop: 20,
  },
});

export const productsInfo = [
  {
    id: 100001,
    name: "Taladro Dewalt",
    image:
      "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/product-01.jpeg",
    // oferta: 2,
    price: "42,000",
  },
  {
    id: 100002,
    name: "Mazo Stanley",
    image:
      "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/product-02.jpeg",
    // oferta: 3,
    price: "11,000",
  },
  {
    id: 100003,
    name: "Cerámica",
    image:
      "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/product-03.jpeg",
    oferta: "8,000",
    price: "6,000",
  },
  {
    id: 100004,
    name: "Cerradura principal",
    image:
      "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/product-04.jpeg",
    oferta: "49,000",
    price: "38,000",
  },
  {
    id: 100005,
    name: "Carretillo Bellota",
    image:
      "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/product-05.jpeg",
    oferta: "28,000",
    price: "24,000",
  },
  {
    id: 100006,
    name: "Grifo de agua moderno",
    image: "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/product-06.jpg",
    // oferta: 13,
    price: "31,000",
  },
  {
    id: 100007,
    name: "Servicio sanitario",
    image:
      "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/product-07.jpeg",
    oferta: "82,000",
    price: "67,000",
  },
  {
    id: 100008,
    name: "Lámpara moderna",
    image:
      "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/product-08.jpeg",
    oferta: "31,000",
    price: "28,000",
  },
  {
    id: 100009,
    name: "Madera de pino 2x2",
    image:
      "https://nabetse-cloud.s3.amazonaws.com/MSC-elements/product-09.jpeg",
    oferta: "4,000",
    price: "3,000",
  },
];

const Product = ({ product }: any) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      {product.oferta && (
        <Chip
          style={{
            position: "absolute",
            marginLeft: 10,
            marginTop: 10,
            fontStyle: "oblique",
            zIndex: 1,
            backgroundColor: "#F8EB24",
          }}
          label={`Oferta`}
          // color="primary"
        />
      )}
      <CardActionArea
        onClick={() =>
          history.push(Routes.PRODUCT.replace(":id", `${product.id}`), {
            id: product.id,
          })
        }
      >
        <CardMedia
          className={classes.media}
          image={product.image}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{
              borderBottom: "1px solid rgb(204 215 228)",
              paddingBottom: 10,
              fontWeight: "bold",
              color: "rgb(73, 73, 73)",
            }}
          >
            {product.name}
          </Typography>
          {
            <Typography variant="body2" color="textSecondary" component="p">
              Descripción / Información breve del producto......
            </Typography>
          }
        </CardContent>
      </CardActionArea>
      <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
        {product.oferta && (
          <Button
            style={{ color: "lightgray", fontWeight: "bold", fontSize: 12 }}
          >
            Antes: ₡ {product.oferta}
          </Button>
        )}
        <Button
          size="small"
          // color="primary"
          style={{ color: "#0691d1", fontWeight: "bold", fontSize: 22 }}
          onClick={() => history.push(Routes.PRODUCT.replace(":id", "111111"))}
        >
          ₡ {product.price}
        </Button>
      </CardActions>
    </Card>
  );
};

const Products = () => {
  return (
    <div style={{ backgroundColor: "#F0F4F8" }}>
      <div
        style={{
          backgroundColor: "#0691d1",
          height: 400,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ color: "#FFFFFF", fontSize: 30 }}>
          Productos Header Banner - Publicidad
        </h2>
      </div>
      <div
        style={{
          backgroundColor: "#F8EB24",
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ color: "#FFFFFF", fontSize: 30 }}>Filtros</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          paddingTop: 40,
          paddingBottom: 40,
        }}
      >
        {productsInfo.map((product, index) => (
          <Product key={index} product={product} />
        ))}
      </div>
    </div>
  );
};

export default Products;
