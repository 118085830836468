import React from "react";
import { openWhatsAppInNewTab } from "../../../share/components/WhatsappButton/WhatsappButton";
import contactenos from "../../../share/images/contactenos.png";
import "./footer.css";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "#f1f1f1",
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
        flex: 1,
        width: "100%",
      }}
    >
      <div
        style={{
          flex: 1,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 1,
            borderTop: "2px solid #0691d1",
            marginLeft: "20%",
            marginRight: "20%",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          paddingTop: 40,
          paddingBottom: 50,
          paddingLeft: 30,
          paddingRight: 20,
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div className="footer-navigation">
          <img
            src="https://msc-images-prod.s3.amazonaws.com/logo-nav.png"
            alt="MSC Logo"
            style={{ height: 60, width: "auto" }}
          />
        </div>
        <div
          className="footer-navigation"
          style={{
            borderLeft: "4px solid #0691d1",
            borderRight: "4px solid #0691d1",
            height: 75,
            paddingLeft: 25,
            paddingRight: 25,
            marginRight: 25,
            marginLeft: 25,
          }}
        >
          <h3
            style={{
              color: "#0691d1",
              fontWeight: 400,
              letterSpacing: 2,
              marginTop: 5,
              fontSize: 18,
            }}
            className="footer-navigation-label"
          >
            ¿Quiénes somos?
          </h3>
          <h3
            style={{
              color: "#0691d1",
              fontWeight: 400,
              letterSpacing: 2,
              marginTop: 5,
              fontSize: 18,
            }}
            className="footer-navigation-label"
          >
            Sucursales
          </h3>
          <h3
            style={{
              color: "#0691d1",
              fontWeight: 400,
              letterSpacing: 2,
              marginBottom: 0,
              fontSize: 18,
            }}
            className="footer-navigation-label"
          >
            Cotizaciones
          </h3>
        </div>
        <div>
          <img
            alt="quienes-somos"
            src={contactenos}
            className="contactenos-title"
          />
          <h3
            style={{
              color: "#0691d1",
              margin: 0,
              fontWeight: 400,
              letterSpacing: 2,
              marginBottom: 0,
              fontSize: 18,
            }}
            className="footer-navigation-label"
          >
            6296-3093
          </h3>
          <h3
            style={{
              color: "#0691d1",
              margin: 0,
              fontWeight: 400,
              letterSpacing: 2,
              marginBottom: 0,
              fontSize: 18,
            }}
            className="footer-navigation-label"
          >
            cotizaciones1@maderassancarlos.com
          </h3>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="social-networks"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-end",
            }}
          >
            <img
              onClick={() =>
                window.open(
                  "https://www.facebook.com/FerreteriaMaderasSanCarlosPalmares"
                )
              }
              src="https://msc-images-prod.s3.amazonaws.com/images/social-media/facebook.png"
              alt="MSC Logo"
              style={{
                height: 50,
                width: 50,
                marginTop: 5,
                marginRight: 6,
                cursor: "pointer",
              }}
            />
            <img
              onClick={() =>
                window.open("https://www.instagram.com/ferreteriamscpalmares/")
              }
              src="https://msc-images-prod.s3.amazonaws.com/images/social-media/instagram.png"
              alt="MSC Logo"
              style={{
                height: 40,
                width: 40,
                marginTop: 10,
                cursor: "pointer",
              }}
            />
            <img
              onClick={openWhatsAppInNewTab}
              src="https://msc-images-prod.s3.amazonaws.com/images/social-media/whatsapp.png"
              alt="MSC Logo"
              style={{ height: 60, width: 60, cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
      <div style={{ flex: 1, width: "100%" }}>
        <div
          style={{
            flex: 1,
            backgroundColor: "#0691d1",
            display: "flex",
            height: 60,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            className="derechos-reservados"
            style={{ color: "#FFFFFF", margin: 0 }}
          >
            © Todos Los Derechos Reservados Ferretería Maderas San Carlos{" "}
            {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
