import * as ReactGA from "react-ga";
import * as React from "react";
import { useLocation } from "react-router";
export const useAnalytics = (trackId: string) => {
  const location = useLocation();
  React.useEffect(() => {
    ReactGA.initialize(trackId);
  }, [trackId]);
  React.useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);
};
