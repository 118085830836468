const Routes = {
  BRANCH_OFFICES: "/sucursales",
  BRANCH_OFFICE_F1: "/sucursal/ferreteria-centro",
  BRANCH_OFFICE_F2: "/sucursal/centro-mueblero",
  BRANCH_OFFICE_F3: "/sucursal/sala-de-acabados",
  BRANCH_OFFICE_F4: "/sucursal/sala-de-ceramica-y-acabados",
  COMPANY: "/quienes-somos",
  CONTACT: "/contactenos",
  COMPRAS: '/compras',
  FORBIDDEN: "/not-found",
  JOBS: "/unete-a-nuestro-equipo",
  QUOTATIONS: "/cotizaciones",
  PRODUCTS: "/productos",
  PRODUCT: "/producto/:id",
  PROMOTIONS: "/promociones",
  ROOT: "/"
};

export default Routes;
